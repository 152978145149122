<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>Product Stats</b-card-title>
        <b-card-text class="font-small-2">
          Last 30 days
        </b-card-text>
      </div>

      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item href="#">
          Last 28 Days
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Month
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Year
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <!-- body -->
    <b-card-body>

      <div
        v-for="(product,index) in productData"
        :key="product.browserImg"
        class="browser-states"
      >
        <b-media no-body>
          <b-media-body>
            <h6 class="align-self-center my-auto">
              {{ product.name }}
            </h6>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">{{ product.share.toFixed(2) }}%</span>
          <vue-apex-charts
            type="radialBar"
            height="30"
            width="30"
            :options="chartData[index].options"
            :series="chartData[index].series"
          />
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaBody, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import axios from '@axios'
import { $api } from '@apiConfig'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaBody,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
  },
  data() {
    return {
      chartData: [],
      chartClone: {},
      chartColor: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger],
      chartSeries: [],
      productData: [],
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%',
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
    }
  },
  created() {
    this.getOrderData()
  },
  methods: {
    async getOrderData() {
      axios
        .get($api.app.order)
        .then(response => {
          const topProducts = {}
          let total = 0
          response.data.forEach(order => {
            order.items.forEach(item => {
              if (!item.product) return
              if (item.product.id in topProducts) {
                topProducts[item.product.id].quantity += item.quantity
                total += item.quantity
              } else {
                topProducts[item.product.id] = {
                  id: item.product.id,
                  displayName: item.product.displayName,
                  quantity: item.quantity,
                }
              }
            })
          })
          this.productData = []
          this.chartSeries = []
          Object.keys(topProducts).forEach(product => {
            topProducts[product].share = topProducts[product].quantity / total
            this.productData.push({
              name: topProducts[product].displayName,
              share: topProducts[product].share * 100,
            })
            this.chartSeries.push(topProducts[product].share * 100)
          })
          for (let i = 0; i < this.productData.length; i += 1) {
            const chartClone = JSON.parse(JSON.stringify(this.chart))
            chartClone.options.colors[0] = this.chartColor[i]
            chartClone.series[0] = this.chartSeries[i]
            this.chartData.push(chartClone)
          }
        })
    },
  },
}
</script>

<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-card-header>
      <div>
        <b-card-title>Performance</b-card-title>
        <b-card-text class="font-small-2">
          Last 30 days
        </b-card-text>
      </div>
    </b-card-header>
    <b-card-body>
      <b-table
        :items="tableData"
        responsive
        :sort-by.sync="sortBy"
        :fields="fields"
        class="mb-0"
      >
        <!-- company -->
        <template #cell(company)="data">
          <div class="d-flex align-items-center">
            <b-avatar
              rounded
              size="32"
              variant="light-company"
            >
              <b-img
                :src="data.item.avatarImg"
                alt="avatar img"
              /></b-avatar>
            <div>
              <div class="font-weight-bolder">
                {{ data.item.title }}
              </div>
              <div class="font-small-2 text-muted">
                {{ data.item.subtitle }}
              </div>
            </div>
          </div>
        </template>

        <!-- views -->
        <template #cell(views)="data">
          <div class="d-flex flex-column">
            <span class="font-weight-bolder mb-25">{{ data.item.viewTitle }}</span>
            <span class="font-small-2 text-muted text-nowrap">{{ data.item.viewsub }}</span>
          </div>
        </template>

        <!-- revenue -->
        <template #cell(revenue)="data">
          {{ data.item.revenue.toLocaleString('en-CA', {
            style: 'currency',
            currency: 'CAD',
          }) }}
        </template>

        <!-- sales -->
        <template #cell(sales)="data">
          <div class="d-flex align-items-center">
            <span class="font-weight-bolder mr-1">{{ data.item.sales }}</span>
          </div>
        </template>
      </b-table>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BTable, BAvatar, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    BAvatar,
    BImg,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        { key: 'company', label: 'NAME' },
        { key: 'revenue', label: 'REVENUE' },
        { key: 'sales', label: 'SALES' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>

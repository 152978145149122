<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <ecommerce-medal :data="data.main" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>
          <!--/ Bar Chart - Orders -->
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col>
        </b-row>
      </b-col>

      <!-- Revenue Report Card -->
      <b-col lg="8">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="8">
        <ecommerce-company-table :table-data="data.companyTable" />
      </b-col>
      <!--/ Company Table Card -->

      <!-- Developer Meetup Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-meetup :data="data.meetup" />
      </b-col>
      <!--/ Developer Meetup Card -->

      <!-- Product Stats Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-product-stats />
      </b-col>
      <!--/ Product Stats  Card -->

      <!-- Goal Overview Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-goal-overview :data="data.todoOverview" />
      </b-col>
      <!--/ Goal Overview Card -->

      <!-- Transaction Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-transactions :data="data.transactionData" />
      </b-col>
      <!--/ Transaction Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import axios from '@axios'
import { $api } from '@apiConfig'
import { getUserData } from '@/auth/utils'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import EcommerceMeetup from './EcommerceMeetup.vue'
import EcommerceProductStats from './EcommerceProductStats.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommerceTransactions from './EcommerceTransactions.vue'

export default {
  components: {
    BRow,
    BCol,
    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceProductStats,
    EcommerceGoalOverview,
    EcommerceTransactions,
  },
  data() {
    return {
      /**
       * Sample data structure and starter data sets
       * TODO: hook up real data
       */
      data: {
        main: {
          name: 'VuexyCRM',
          saleToday: '0',
        },
        statisticsItems: [
          {
            icon: 'TrendingUpIcon',
            color: 'light-primary',
            title: '0',
            subtitle: 'Orders',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'UserIcon',
            color: 'light-info',
            title: '0',
            subtitle: 'Clients',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'BoxIcon',
            color: 'light-danger',
            title: '0',
            subtitle: 'Products',
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'DollarSignIcon',
            color: 'light-success',
            title: '$0',
            subtitle: 'Revenue',
            customClass: '',
          },
        ],
        statisticsOrder: {
          series: [
            {
              name: 'Week 26',
              data: [45, 85, 65, 85, 65],
            },
            {
              name: 'Week 27',
              data: [65, 85, 25, 45, 15],
            },
            {
              name: 'Week 28',
              data: [15, 85, 85, 25, 25],
            },
          ],
        },
        statisticsProfit: {
          series: [
            {
              data: [0, 20, 5, 30, 15, 45],
            },
          ],
        },
        earningsChart: {
          series: [53, 16, 31],
        },
        revenue: {
          years: ['2021', '2020'],
          total: '25,852',
          sales: '201',
          salesReport: {
            series: [
              {
                name: 'Earning',
                data: [95, 177, 284, 256, 105, 63, 168, 218, 72, 168, 218, 72],
              },
              {
                name: 'Expense',
                data: [-145, -80, -60, -180, -100, -60, -85, -75, -100, -60, -85, -75],
              },
            ],
          },
          budgetChart: {
            series: [
              {
                data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62, 168, 218, 72],
              },
              {
                data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27, 25, 15, 20],
              },
            ],
          },
        },
        companyTable: [],
        meetup: {
          mediaData: [
            { avatar: 'CalendarIcon', title: 'Sat, July 24, 2021', subtitle: '10:AM to 6:PM' },
            { avatar: 'MapPinIcon', title: 'Hilton Hotel', subtitle: 'Downtown, Markham' },
          ],
          avatars: [
            { avatar: 'https://ui-avatars.com/api/?name=Billy Hopkins', fullName: 'Billy Hopkins' },
            { avatar: 'https://ui-avatars.com/api/?name=Amy Carson', fullName: 'Amy Carson' },
            { avatar: 'https://ui-avatars.com/api/?name=Brandon Miles', fullName: 'Brandon Miles' },
            { avatar: 'https://ui-avatars.com/api/?name=Daisy Weber', fullName: 'Daisy Weber' },
            { avatar: 'https://ui-avatars.com/api/?name=Jenny Looper', fullName: 'Jenny Looper' },
          ],
        },
        todoOverview: {
          completed: 0,
          inProgress: 0,
          series: [0],
        },
        transactionData: [
          {
            mode: 'EMT',
            types: 'email transfer',
            avatar: 'PocketIcon',
            avatarVariant: 'light-primary',
            payment: '-$74',
            deduction: true,
          },
          {
            mode: 'Debit',
            types: 'terminal',
            avatar: 'CheckIcon',
            avatarVariant: 'light-success',
            payment: '+$480',
            deduction: false,
          },
          {
            mode: 'Cash',
            types: 'cash payments',
            avatar: 'DollarSignIcon',
            avatarVariant: 'light-danger',
            payment: '+$480',
            deduction: false,
          },
          {
            mode: 'Credit',
            types: 'credit card',
            avatar: 'CreditCardIcon',
            avatarVariant: 'light-warning',
            payment: '-$23',
            deduction: true,
          },
          {
            mode: 'Other',
            types: 'other',
            avatar: 'TrendingUpIcon',
            avatarVariant: 'light-info',
            payment: '+$98',
            deduction: false,
          },
        ],
      },
    }
  },
  created() {
    // data
    Promise.all([this.getOrderData(), this.getClientData(), this.getProductData()])
      .then(() => {
        const userData = getUserData()
        this.data.main.name = userData.firstName || 'There'
      })
  },
  methods: {
    async getOrderData() {
      const issuedDate = moment().add(-30, 'days').toISOString()
      const performanceReport = {}
      axios
        .get(`${$api.app.order}?issuedDate_gte=${issuedDate}`)
        .then(response => {
          let revenue = 0
          response.data.forEach(order => {
            revenue += order.paymentDetails.total

            const salesperson = `${order.salesperson.firstName} ${order.salesperson.lastName}`
            if (salesperson in performanceReport) {
              performanceReport[salesperson].revenue += order.paymentDetails.total
              // eslint-disable-next-line no-plusplus
              performanceReport[salesperson].sales++
            } else {
              performanceReport[salesperson] = {
                revenue: order.paymentDetails.total,
                sales: 1,
                avatarImg: `https://ui-avatars.com/api/?name=${salesperson}`,
                title: salesperson,
                subtitle: order.salesperson.email,
                avatarIcon: 'MonitorIcon',
                avatarColor: 'light-primary',
                loss: false,
              }
            }
          })
          Object.keys(performanceReport).forEach(salesperson => {
            this.data.companyTable.push(performanceReport[salesperson])
          })
          console.log(performanceReport)
          this.data.main.saleToday = revenue.toLocaleString('en-CA', {
            style: 'currency',
            currency: 'CAD',
          })
          this.data.statisticsItems[3].title = revenue.toLocaleString('en-CA', {
            style: 'currency',
            currency: 'CAD',
          })
        })

      axios
        .get(`${$api.app.order}/count?issuedDate_gte=${issuedDate}`)
        .then(response => {
          this.data.statisticsItems[0].title = response.data
        })
    },
    async getClientData() {
      axios
        .get(`${$api.app.client}/count`)
        .then(response => {
          this.data.statisticsItems[1].title = response.data
        })
    },
    async getProductData() {
      axios
        .get(`${$api.app.product}/count`)
        .then(response => {
          this.data.statisticsItems[2].title = response.data
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
